import React, { useRef, useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, Divider } from '@mui/material';

const RelatedStocks = ({ relatedStocks }) => {
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const startDragging = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const onDrag = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 3; // The speed of scrolling
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  useEffect(() => {
    const slider = sliderRef.current;
    slider.addEventListener('mousemove', onDrag);
    slider.addEventListener('mouseleave', () => setIsDragging(false));
    slider.addEventListener('mouseup', () => setIsDragging(false));

    return () => {
      slider.removeEventListener('mousemove', onDrag);
      slider.removeEventListener('mouseleave', () => setIsDragging(false));
      slider.removeEventListener('mouseup', () => setIsDragging(false));
    };
  }, [onDrag]);

  return (
    <Box sx={{ mt: 4, width: '100%', overflowX: 'auto' }}>
      <Typography variant="h6" gutterBottom>
        Related Stocks
      </Typography>
      <Divider sx={{ backgroundColor: '#fff', mb: 2.5 }} />
      <Grid container spacing={2} ref={sliderRef} className="draggable-container" sx={{
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    cursor: 'grab',
    '&.active': { cursor: 'grabbing' },
    '&::-webkit-scrollbar': { display: 'none' },
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    userSelect: 'none', // Prevent text selection during drag
    '& .MuiGrid-item': {
      flex: '0 0 auto'
    }
      }} onMouseDown={startDragging}>
        {relatedStocks.map((relatedStock, index) => (
          <Grid item xs={6} sm={4} md={3} key={index} sx={{ minWidth: 200, maxWidth: 200 }}>
            <Paper sx={{
              p: 2,
              minHeight: 140,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              cursor: 'pointer',
              backgroundColor: '#2a2a2a',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#1c1c1c'
              }
            }} onClick={() => window.location.href = `https://pocketquant.io/stocks/${relatedStock.ticker}`}>
              <Typography variant="subtitle1">
                {relatedStock.ticker}
              </Typography>
              <Typography variant="body2">
                {relatedStock.company_name}
              </Typography>
              <Typography variant="body2">
                Price: {relatedStock.price}
              </Typography>
              <Typography variant="body2" sx={{ color: relatedStock.change.startsWith('+') ? '#00c853' : '#d32f2f' }}>
                Change: {relatedStock.change}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RelatedStocks;
