// React component code including price flip animation
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Container, TextField, Button, Link, Grid, Paper, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import './App.css';
import RelatedStocks from './RelatedStocks';

// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// Styled components for a cleaner UI
const StyledContainer = styled(Container)({
  padding: '20px',
  backgroundColor: '#1F1F1F',
  color: '#fff',
  borderRadius: '10px',
  boxShadow: 'none',
  marginTop: '20px',
  minHeight: '600px',
});

const Flipper = styled('div')(({ theme }) => ({
  display: 'inline-block',
  marginLeft: '10px',
  fontSize: '18px',
  height: '24px',
  width: '80px',
  perspective: '1000px'
}));

const Card = styled('div')(({ isFlipped }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  transition: 'transform 0.6s',
  transformStyle: 'preserve-3d',
  transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
}));

const CardFace = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backfaceVisibility: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  borderRadius: '4px'
});

const CardFront = styled(CardFace)({
  backgroundColor: '#00c853',
});

const CardBack = styled(CardFace)({
  backgroundColor: '#d32f2f',
  transform: 'rotateY(180deg)',
});

const StockChart = () => {
  const { ticker: initialTicker } = useParams();
  const [ticker, setTicker] = useState(initialTicker || '');
  const [companyName, setCompanyName] = useState('');
  const [chartData, setChartData] = useState(null);
  const [stockInfo, setStockInfo] = useState(null);
  const [stockStats, setStockStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [relatedStocks, setRelatedStocks] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [currentPrice, setCurrentPrice] = useState('');
  const [isFlipped, setIsFlipped] = useState(false);
  const sliderRef = React.useRef(null);

  React.useEffect(() => {
    const slider = sliderRef.current;
    if (!slider) return;

    let isDown = false;
    let startX;
    let scrollLeft;

    const handleMouseDown = (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };

    const handleMouseLeave = () => {
      isDown = false;
      slider.classList.remove('active');
    };

    const handleMouseUp = () => {
      isDown = false;
      slider.classList.remove('active');
    };

    const handleMouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; // Adjust scroll speed if necessary
      slider.scrollLeft = scrollLeft - walk;
    };

    slider.addEventListener('mousedown', handleMouseDown);
    slider.addEventListener('mouseleave', handleMouseLeave);
    slider.addEventListener('mouseup', handleMouseUp);
    slider.addEventListener('mousemove', handleMouseMove);

    return () => {
      // Cleanup the event listeners when the component unmounts
      slider.removeEventListener('mousedown', handleMouseDown);
      slider.removeEventListener('mouseleave', handleMouseLeave);
      slider.removeEventListener('mouseup', handleMouseUp);
      slider.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    if (initialTicker) {
      fetchChartData(initialTicker);
    }
  }, [initialTicker]);

  useEffect(() => {
    // Simulate fetching the last closing price
    const timeout = setTimeout(() => {
      setCurrentPrice('123.45');
      setIsFlipped(!isFlipped);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const fetchChartData = async (tickerSymbol) => {
    setLoading(true);
    setError('');
    const apiBaseUrl = `https://8poesonquj.execute-api.us-east-1.amazonaws.com/prod`;

    try {
      // Start all fetch requests simultaneously
      const urls = [
        `${apiBaseUrl}/stats/${tickerSymbol}`,
        `${apiBaseUrl}/stocks/${tickerSymbol}`,
        `${apiBaseUrl}/related/${tickerSymbol}`
      ];
      const [statsResponse, stockResponse, relatedResponse] = await Promise.all(urls.map(url => fetch(url)));

      // Convert all responses to JSON simultaneously
      const [statsData, stockData, relatedData] = await Promise.all([
        statsResponse.json(),
        stockResponse.json(),
        relatedResponse.json()
      ]);

      if (statsResponse.ok && stockResponse.ok && relatedResponse.ok) {
        setStockStats(statsData.stats);
        setChartData({
          labels: stockData.dates,
          datasets: [{
            label: `Stock Price for ${stockData.ticker}`,
            data: stockData.prices,
            borderColor: '#00c853',
            backgroundColor: 'rgba(0, 200, 83, 0.1)',
          }],
        });
        setStockInfo(stockData.info);
        setCompanyName(stockData.info['Company Name'])
        setRelatedStocks(relatedData.related.sort((a, b) => {
          const changeA = parseFloat(a.change.replace('%', ''));
          const changeB = parseFloat(b.change.replace('%', ''));
          return changeB - changeA;
        }).slice(0, 8));
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      setError('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };


  // const fetchChartData = async (tickerSymbol) => {
  //   setLoading(true);
  //   setError('');
  //   const apiBaseUrl = `https://8poesonquj.execute-api.us-east-1.amazonaws.com/prod`;
  //   try {
  //     const statsResponse = await fetch(`${apiBaseUrl}/stats/${tickerSymbol}`);
  //     const stockResponse = await fetch(`${apiBaseUrl}/stocks/${tickerSymbol}`);
  //     const relatedResponse = await fetch(`${apiBaseUrl}/related/${tickerSymbol}`);

  //     const statsData = await statsResponse.json();
  //     const stockData = await stockResponse.json();
  //     const relatedData = await relatedResponse.json();

  //     if (statsResponse.ok && stockResponse.ok && relatedResponse.ok) {
  //       setStockStats(statsData.stats);
  //       setChartData({
  //         labels: stockData.dates,
  //         datasets: [{
  //           label: `Stock Price for ${stockData.ticker}`,
  //           data: stockData.prices,
  //           borderColor: '#00c853',
  //           backgroundColor: 'rgba(0, 200, 83, 0.1)',
  //         }],
  //       });
  //       setStockInfo(stockData.info);
  //       setRelatedStocks(relatedData.related.sort((a, b) => {
  //         const changeA = parseFloat(a.change.replace('%', ''));
  //         const changeB = parseFloat(b.change.replace('%', ''));
  //         return changeB - changeA;
  //       }).slice(0, 8));

  //     } else {
  //       throw new Error('Failed to fetch data');
  //     }
  //   } catch (error) {
  //     setError('Failed to fetch data');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleInputChange = (e) => {
    setTicker(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (ticker) {
      fetchChartData(ticker);
    }
  };

  const toggleDescription = () => setShowFullDescription(!showFullDescription);

  const handleDrag = (event) => {
    const slider = event.currentTarget;
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', (e) => {
      console.log('Mouse down');
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });


    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });

    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });

    slider.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 2; // the *2 multiplies the scroll speed
      slider.scrollLeft = scrollLeft - walk;
    });
  }



  // Use useEffect to attach the event listener once the component is mounted
  React.useEffect(() => {
    const gridContainer = document.querySelector('.draggable-container');
    if (gridContainer) {
      handleDrag(gridContainer);
    }
  }, []);


  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
      <StyledContainer maxWidth="md">
        <Typography variant="h6" gutterBottom align="center" sx={{ color: '#ffff', position: 'relative' }}>
          {/* {initialTicker ? initialTicker.toUpperCase() : 'Enter Company Ticker'} */}
          {companyName && initialTicker ? `${companyName} (${initialTicker.toUpperCase()})` : ''}

          {/* {initialTicker ? initialTicker.toUpperCase() : 'Enter Company Ticker'} */}

          {/* <Flipper>
            <Card isFlipped={isFlipped}>
              <CardFront>{`$${currentPrice}`}</CardFront>
              <CardBack>{`$${parseFloat(currentPrice) + 0.05}`}</CardBack>
            </Card>
          </Flipper> */}
        </Typography>

        {!initialTicker && (
          <form onSubmit={handleFormSubmit} style={{ textAlign: 'center' }}>
            <TextField
              label="Enter Ticker"
              variant="outlined"
              value={ticker}
              onChange={handleInputChange}
              style={{ marginRight: '10px', backgroundColor: '#fff', borderRadius: '5px' }}
              inputProps={{ style: { color: '#000' } }}
            />
            <Button type="submit" variant="contained" style={{ backgroundColor: '#00c853', color: '#fff' }}>
              Get Data
            </Button>
          </form>
        )}
        {loading ? (
          <Typography>Loading...</Typography>
        ) : (
          <>
            {chartData && (
              <>
                <Line data={chartData} options={{
                  responsive: true,
                  scales: {
                    x: {
                      grid: {
                        display: false
                      },
                      ticks: {
                        color: '#fff'
                      },
                    },
                    y: {
                      beginAtZero: false,
                      grid: {
                        color: 'rgba(255, 255, 255, 0.1)'
                      },
                      ticks: {
                        color: '#fff'
                      },
                    }
                  },
                  plugins: {
                    legend: {
                      display: false
                    },
                    tooltip: {
                      enabled: false,
                      external: (context) => {
                        const tooltipModel = context.tooltip;
                        if (tooltipModel.opacity === 0) {
                          setCurrentPrice('');
                          return;
                        }
                        setCurrentPrice(`$${tooltipModel.dataPoints[0].formattedValue}`);
                      }
                    },
                  },
                  onHover: (event, chartElement) => {
                    if (chartElement.length > 0) {
                      const index = chartElement[0].index;
                      setCurrentPrice(`$${chartData.datasets[0].data[index]}`);
                    }
                  },
                }} />
                {stockInfo && (
                  <Box mt={4} sx={{ width: '100%' }}>
                    <Typography variant="h6" gutterBottom>
                      About
                    </Typography>
                    <Divider sx={{ backgroundColor: '#fff' }} />
                    {stockInfo.About && (
                      <Box display="flex" alignItems="center" sx={{ marginTop: 2, marginBottom: '25px' }}>
                        <Typography variant="body1" sx={{ flexGrow: 1, whiteSpace: 'pre-line' }}>
                          {showFullDescription ? stockInfo.About : `${stockInfo.About.substring(0, 300)}.`}
                          <Link href="#" onClick={toggleDescription} sx={{ marginLeft: 1, verticalAlign: 'baseline' }}>
                            {showFullDescription ? 'Show Less' : 'Show More'}
                          </Link>
                        </Typography>
                      </Box>
                    )}
                    <Grid container spacing={4} sx={{ marginBottom: '35px' }}>
                      {['Founded', 'Website', 'Headquarters', 'CEO', 'Employees', 'Ticker', 'Primary exchange'].map(key => (
                        <Grid item xs={3} key={key}>
                          <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
                            {key.replace(/([A-Z])/g, ' $1').trim().replace('C E O', 'CEO')}
                          </Typography>
                          <Typography variant="body2">
                            {stockInfo[key]}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                    <Typography variant="h6" gutterBottom>
                      Key Statistics
                    </Typography>
                    <Divider sx={{ backgroundColor: '#fff' }} />
                    {stockStats ? (
                      <Grid container spacing={2} sx={{ marginTop: 2 }}>
                        {Object.entries(stockStats).map(([key, value]) => (
                          <Grid item xs={6} sm={4} key={key}>
                            <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
                              {key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()).replace('Pe', 'PE').replace('Eps', 'EPS').replace('Ceo', 'CEO').replace('P E', 'P/E').replace('Ebitda', 'EBITDA')}
                            </Typography>
                            <Typography variant="body2">
                              {typeof value === 'number' ? new Intl.NumberFormat().format(value) : value}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>

                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        No statistics available.
                      </Typography>
                    )}
                  </Box>
                )}

                {relatedStocks && (<RelatedStocks relatedStocks={relatedStocks} />)}

              </>
            )}
            {!loading && !chartData && <Typography>Enter a ticker to see the stock chart.</Typography>}
          </>
        )}
        {error && <Typography color="error">{error}</Typography>}
      </StyledContainer>
    </Box>
  );
};

export default StockChart;
